import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/Footer.js";
import MainFeature1 from "components/features/FeaturesButtons.js";
import MainFeature2 from "components/features/FeaturesButtonsDoar1Button";
import Features from "components/features/MainFeature2.js";
import { NavLink, NavLinks, PrimaryLink as LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import styled from "styled-components";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
const HighlightedText = tw.span`text-primary-500`

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} {
    ${tw`text-white hover:border-blue-300 hover:text-white`}
  }
  $ ${NavLink}, ${LogoLink} {
    ${tw`text-blue-800 hover:border-blue-300 hover:text-blue-800`}
  }
  ${NavToggle}.closed {
    ${tw`text-blue-800 hover:text-primary-700`}
  }
`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const buttonRounded = true;


export default () => {

const buttonRoundedCss = buttonRounded && tw`rounded-full`;
const navLinks = [
  <NavLinks key={1}>
    <NavLink href="/">
      Acasă
    </NavLink>
    <NavLink href="/Contact">
      Contact
    </NavLink>
    <NavLink href="/IntrebariFrecvente">
    Întrebări Frecvente
    </NavLink>
  </NavLinks>,
    <NavLinks key={2}>
  <PrimaryLink css={buttonRoundedCss} href="/AboutUs">
      English
    </PrimaryLink>
  </NavLinks>
]

  return (
    <AnimationRevealPage>
      <HeroContainer links={navLinks}>
        <StyledHeader links={navLinks} />
        </HeroContainer>
      <MainFeature2
        subheading={<Subheading>Despre Extron Energy Vision</Subheading>}
        description="Suntem o firma modernă adaptată cerințelor curente pe piața energiei, menită să ajute și să dezvolte societăți economice care se confruntă cu dificultăți în îmbuătățirea eficienței energetice și reducerea amprentei de carbon"
        heading="Suntem o companie MODERNĂ"
        buttonRounded={false}
        primaryButtonText="Contactează-ne!"
        primaryButtonUrl = "/ContactUs"
        imageSrc="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Viziunea noastră</Subheading>}
        description="Luptăm pentru un viitor mai curat și verde, în care impactul asupra mediului este redus semnificativ. Promovăm soluții inovatoare pentru reducerea amprentei de carbon.
        Sprijinim toți partenerii noștri în completarea declarațiilor de tip ESG (Environmental, social, and corporate governance) și CDP (Carbon Disclosure Project)"
        heading="Pentru un viitor SUSTENABIL"
        buttonRounded={false}
        primaryButtonText="Mai multe despre CDP"
        primaryButton2Text="Mai multe despre ESG"
        primaryButton2Url = "https://www.esgportal.eu/"
        primaryButtonUrl = "https://www.cdp.net/en"
        imageSrc="https://images.unsplash.com/photo-1521618755572-156ae0cdd74d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"
        textOnLeft={false}
      />
      <Features
        heading={<>Atribute <HighlightedText> Calitative</HighlightedText></>}
        subheading= "Ceea ce ne definește"
        description= "De ce beneficiază partenerii noștri"
        cards={[
          {imageSrc:ShieldIconImage,
            title:"Siguranță",
           description: "Asigurăm securitatea energetică a afacerii dumneavoastră"},
          {imageSrc: SupportIconImage,
          title: "Asistență 24/7" ,
            description: "Suntem mereu disponibili pentru partenerii noștri"},
          {imageSrc: CustomizeIconImage,
            title: "Personalizat", 
            description: "Servicii adaptate nevoilor companiei"},
          {imageSrc: FastIconImage,
          title: "Demn de încredere", 
          description: "Ne dorim parteneriate pe termen lung cu partenerii noștri"},
          {imageSrc: ReliableIconImage,
          title: "Rapid", 
          description: "Intervenții prompte și de calitate"},
          {imageSrc:SimpleIconImage,
          title: "Ușor", 
          description: "Comunicare eficientă la standarde înalte"}
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
