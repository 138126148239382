import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import Hero from "components/hero/Hero.js";
import Features from "components/features/MainFeature2.js";
import MainFeature from "components/features/MainFeature.js";
import FeatureStats from "components/features/FeatureStats.js";
import FAQ from "components/faqs/FAQs1.js";
import Footer from "components/footers/Footer.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { NavLink, NavLinks, PrimaryLink as LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import styled from "styled-components";
import Header from "components/headers/light.js";



export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
const HighlightedText = tw.span`text-primary-900`

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} {
    ${tw`text-white hover:border-blue-300 hover:text-white`}
  }
  $ ${NavLink}, ${LogoLink} {
    ${tw`text-blue-800 hover:border-blue-300 hover:text-blue-800`}
  }
  ${NavToggle}.closed {
    ${tw`text-blue-800 hover:text-primary-700`}
  }
`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const buttonRounded = true;


export default () => {

    const buttonRoundedCss = buttonRounded && tw`rounded-full`;
    const navLinks = [
      <NavLinks key={1}>
        <NavLink href="/DespreNoi">Despre noi</NavLink>
        <NavLink href="/Contact">Contact</NavLink>
        <NavLink href="/IntrebariFrecvente">Întrebări Frecvente </NavLink>  
      </NavLinks>,
      <NavLinks key={2}>
      <PrimaryLink css={buttonRoundedCss} href="/en">
          English
        </PrimaryLink>
      </NavLinks>
    ]

    return (
    <AnimationRevealPage>
        <HeroContainer links={navLinks}>
        <StyledHeader links={navLinks} />
        </HeroContainer>
      <Hero
      heading = "Servicii energetice adaptate nevoilor tale!"
      features = {["Specialisti în energie", "Servicii de calitate", "Preturi adaptate cerintelor"]}
      description = "Viziunea Extron Energy Vision recurge la experiența profesioniștilor săi pentru a îmbunătăți considerabil siguranța și securitatea energetică a companiei dumneavoastră!"
      imageSrc = "https://images.unsplash.com/photo-1545208942-e1c9c916524b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80"
      primaryButtonUrl = "/ContactUs"
      primaryButtonText = "Contactați-ne!"
   
      />
      <FeatureStats
      heading = "Avem angajați calificați atestați de Ministerul Energiei din România!"
      description= "Angajații noștri sunt calificați pentru munca pe care o îndeplinesc și dau dovadă de tenacitate și experiență"
      stats={[{key:"Energetic", value:"Management"}, {key:"Complex", value:"Audit"}, {key:"Inginerie", value:"Proiectare"}]}
      />
      <Features 
        heading={<>Atribute <HighlightedText> calitative</HighlightedText></>}
        subheading= "Ceea ce ne definește"
        description= "De ce beneficiază partenerii noștri"
        cards={[
        {imageSrc:ShieldIconImage,
          title:"Siguranță",
         description: "Asigurăm securitatea energetică a afacerii dumneavoastră"},
        {imageSrc: SupportIconImage,
        title: "Asistență 24/7" ,
          description: "Suntem mereu disponibili pentru partenerii noștri"},
        {imageSrc: CustomizeIconImage,
          title: "Personalizat", 
          description: "Servicii adaptate nevoilor companiei dumneavoastră"},
        {imageSrc: FastIconImage,
        title: "Demn de încredere", 
        description: "Ne dorim parteneriate pe termen lung cu partenerii noștri"},
        {imageSrc: ReliableIconImage,
        title: "Rapid", 
        description: "Intervenții prompte și de calitate"},
        {imageSrc:SimpleIconImage,
        title: "Ușor", 
        description: "Comunicare eficientă la standarde înalte"}
        ]}
      />
      <MainFeature
        subheading="Expertiza noastră"
        heading={<>Realizat de și pentru<HighlightedText> Profesioniști</HighlightedText></>}
        description="Membrii echipei sunt specialiști atestați, autorizați de ME (Ministerul Energiei) și ANRE (Autoritatea Națională pentru Reglementare în Energie) cu recunoaștere internă și EUROPEANĂ"
        primaryButtonText = "Despre noi"
        primaryButtonUrl = "/DespreNoi"
        features={[{
          Icon: BriefcaseIcon,
          title:"Profesionalism",
          description:"Printre cei mai buni profesionisti din țară",
          iconContainerCss: tw`bg-teal-300 text-teal-800`},
          {
          Icon:MoneyIcon,
          title:"Accesibil",
          description:"Calitate la prețuri accesibile",
          iconContainerCss: tw`bg-teal-300 text-teal-800`
          }]}
      />
      <FAQ
      subheading = "întrebări frecvente"
      description = "Răspundem la câteva din întrebările dumneavoastră"
      heading={<>Aveți<HighlightedText> întrebări?</HighlightedText></>}
      faqs={[{question:"Din punct de vedere legal, am nevoie de management energetic?", answer:"Conform legii 121/2014, operatorii economici sunt obligați să numească un administrator energetic, certificat de Ministerul Energiei sau de Autoritatea Națională de Reglementare în domeniul Energiei, în termenul de valabilitate a atestatului conform legislației în vigoare, sau să încheie un contract de gestionare a energiei cu o persoană certificată de Direcția pentru Eficiență Energetică, care are statutul de persoană fizică autorizată sau cu o entitate juridică care furnizează servicii energetice, certificată în conformitate cu legea"}, 
      {question:"Din punct de vedere legal, am nevoie de audit energetic?", answer:"Conform legii 121/2014, operatorii economici sunt obligați să efectueze un audit energetic la fiecare 4 ani pe un contur de consum de energie stabilit de operatorul economic, reprezentând cel puțin 50% din consumul total de energie al operatorului economic"},
      {question:"Cine poate presta servicii de management și audit energetic?", answer:"Serviciile de audit și management pot fi efectuate DOAR de către operatorii economici autorizați și certificați de Ministerul Energiei sau de Autoritatea națională de reglementare în domeniul Energiei cu personal specializat, autorizat și certificat în conformitate cu legislația în vigoare"},
      {question:"Pot accesa fonduri europene nerambursabile fără a efectua un audit energetic recent?", answer:"În ghidurile de finanțare este obligatorie efectuarea unui audit energetic pentru a putea accesa fonduri europene nerambursabile"}]}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
