import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/Footer_en.js";
import ContactUsForm from "components/forms/ContactUs.js";
import ContactDetails from "components/cards/ContactDetails.js";
import { NavLink, NavLinks, PrimaryLink as LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import styled from "styled-components";

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
const StyledHeader = styled(Header)`
${tw`pt-8 max-w-none w-full`}
${DesktopNavLinks} {
  ${tw`text-white hover:border-blue-300 hover:text-white`}
}
$ ${NavLink}, ${LogoLink} {
  ${tw`text-blue-800 hover:border-blue-300 hover:text-blue-800`}
}
${NavToggle}.closed {
  ${tw`text-blue-800 hover:text-primary-700`}
}
`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const buttonRounded = true;

export default () => {

  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
    <NavLink href="/en">
      Home
    </NavLink>
    <NavLink href="/AboutUs">
      About
    </NavLink>
    <NavLink href="/faq">
      FAQs
    </NavLink>
  </NavLinks>,
    <NavLinks key={2}>
    <PrimaryLink css={buttonRoundedCss} href="/Contact">
        Română
      </PrimaryLink>
    </NavLinks>
    ];

  return (
    <AnimationRevealPage>
       <HeroContainer links={navLinks}>
        <StyledHeader links={navLinks} />
        </HeroContainer>
        <ContactUsForm 
      heading = "Contact us!"
      submitButtonText = "Submit!"
      subheading = "Contact form"
      title="Title"
      user_email_placeholder="Adresa ta de email"
      user_name_placeholder="Numele tău"
      user_mesaj_placeholder="Mesajul tău"
      />
      <ContactDetails
      heading = "Contact "
      subheading = "Other contact info"
      description= "You can find us at our office, at the phone or at the email"
        cards={[
          {
            title: "Târgoviște",
            description: (
              <>
                <Address>
                  <AddressLine>Street Col Ion Nicolin, No. 4</AddressLine>
                  </Address>
                <Email>extronenergyvision@gmail.com</Email>
                <Email>cristianbanu.cb@gmail.com</Email>
                <Phone>+40 0726257623</Phone>
                <Email>silviubanu.sb@gmail.com</Email>
                <Phone>+40 0737031807</Phone>
              </>
            )
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
