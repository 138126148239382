/* eslint-disable no-unused-vars */
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import Footer from "components/footers/Footer.js";
import FAQ from "components/faqs/FAQs2.js";
import { NavLink, NavLinks, PrimaryLink as LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import styled from "styled-components";

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} {
    ${tw`text-white hover:border-blue-300 hover:text-white`}
  }
  $ ${NavLink}, ${LogoLink} {
    ${tw`text-blue-800 hover:border-blue-300 hover:text-blue-800`}
  }
  ${NavToggle}.closed {
    ${tw`text-blue-800 hover:text-primary-700`}
  }
`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const buttonRounded = true;

export default () => {

  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">
        Acasă
      </NavLink>
      <NavLink href="/DespreNoi">
        Despre noi
      </NavLink>
      <NavLink href="/Contact">
        Contact
      </NavLink>
    </NavLinks>,
      <NavLinks key={2}>
    <PrimaryLink css={buttonRoundedCss} href="/FAQ">
        English
      </PrimaryLink>
    </NavLinks>
  ]

  return (
    <AnimationRevealPage>
      <HeroContainer links={navLinks}>
        <StyledHeader links={navLinks} />
          </HeroContainer>
      <FAQ
      heading = "Întrebări Frecvente"
      description="Iată câteva dintre cele mai frecvente întrebări pe care le primim!"
      faqs={[{question:"Din punct de vedere legal, am nevoie de management energetic?", answer:"Conform legii 121/2014, operatorii economici sunt obligați să numească un administrator energetic, certificat de Ministerul Energiei sau de Autoritatea națională de reglementare în domeniul Energiei, în termenul de valabilitate a atestatului, conform legislației în vigoare, Sau să încheie un contract de gestionare a energiei cu o persoană certificată de Direcția pentru eficiență energetică, care are statutul de persoană fizică autorizată sau cu o entitate juridică care furnizează servicii energetice, certificată în conformitate cu legea"}, 
      {question:"Din punct de vedere legal, am nevoie de audit energetic?", answer:"Conform legii 121/2014, operatorii economici sunt obligați să efectueze un audit energetic la fiecare 4 ani pe un contur de consum de energie stabilit de operatorul economic, reprezentând cel puțin 50% din consumul total de energie al operatorului economic"},
      {question:"Cine poate presta servicii de management și audit energetic?", answer:"Serviciile de audit și management pot fi efectuate NUMAI de către operatorii economici autorizați și certificați de Ministerul Energiei sau de Autoritatea națională de reglementare în domeniul Energiei cu personal specializat, autorizat și certificat în conformitate cu legislația în vigoare"},
      {question:"Pot accesa fonduri europene nerambursabile fără a efectua un audit energetic recent?", answer:"În ghidurile de finanțare, este obligatorie efectuarea unui audit energetic pentru a putea accesa fonduri europene nerambursabile"}]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
