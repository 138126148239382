import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";

import Home from "pages/Home.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import FAQ from "pages/FAQ";
import TermsOfService from "pages/TermsOfService";
import HomeEn from "pages/Home_en";
import AboutUsEn from "pages/AboutUs_en";
import FAQEn from "pages/FAQ_en";
import ContactUsEn from "pages/ContactUs_en";

export default function App() {
  return (
    <Router>
      <Switch>
      <Route path="/En">
          <HomeEn />
          </Route>
        <Route path="/AboutUs">
          <AboutUsEn />
          </Route>
          <Route path="/DespreNoi">
          <AboutUs />
          </Route>
        <Route path="/ContactUs">
          <ContactUsEn />
        </Route>
        <Route path="/Contact">
          <ContactUs />
        </Route>
        <Route path="/faq">
          <FAQEn />
        </Route>
        <Route path="/IntrebariFrecvente">
          <FAQ />
        </Route>
        <Route path="/TermsOfService">
          <TermsOfService />
        </Route>
        <Route path="/">
        <Home />
        </Route>
        </Switch>
    </Router>
  );
}